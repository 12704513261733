//TODO: Figure out proper method for similar listings search
//TODO: Get country data once per session and store in local storage
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import algoliasearch from 'algoliasearch'
import dayjs from 'dayjs'
import Head from 'next/head'
import { useSession } from 'next-auth/react'

import { useDispatch, useSelect } from 'store/index'
import { setQuery } from 'store/search'

import { openQuestionModal, toggleReviewModal } from 'reducers/resultDetail'
import { setDetailPageUrl } from 'reducers/searchData'

import { BookingContext } from 'context/BookingContext'
import { PageHistoryContext } from 'context/PageHistoryContext'

import useQuote from 'hooks/useQuote'
import useBodyClass from 'hooks/bodyClass'
import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useGeolocation from 'hooks/useGeolocation'
import useListingAvailability from 'hooks/useListingAvailability'
import useAvgPrice from 'hooks/useAvgPrice'
import { useListingAmenities } from 'hooks/useListingAmenities'

import { Breadcrumb } from 'components/Breadcrumb'
import MobileImageGallery from 'components/Result-Details/Mobile-Image-Gallery/Image-Gallery'
import DesktopImageGallery from 'components/Result-Details/Desktop-Image-Gallery/Image-Gallery'
import DetailOverview from 'components/Result-Details/Detail-Overview'
// import CancellationPolicy from 'components/Booking/CancellationPolicy'
// import CancellationPolicyModal from 'components/Booking/CancellationPolicy/CancellationPolicyModal'
import Description from 'components/Result-Details/Description'
import SleepingArrangements from 'components/Result-Details/Sleeping-Arrangements'
import Amenities from 'components/Result-Details/Amenities'
import NearbyActivities from 'components/Result-Details/NearbyActivities'
import Gtm from 'components/scripts/Gtm'
import FavoriteButton from 'components/Favorites/FavoriteButton'
import ResultMap from 'components/Result-Details/Result-Map'
import LocationDetails from 'components/Result-Details/Location-Details'
import Reviews from 'components/Result-Details/Reviews'
import Header from 'components/Header/Header'
import SearchBox from 'components/Search/SearchBox'
import ListingOGData from 'components/Result-Details/ListingOGData'
import { withBookingErrorProvider } from 'components/BookingQuote/context/BookingErrorsContext'
import QuestionModal from 'components/Result-Details/QuestionModal'
import ReviewModal from 'components/Result-Details/Review-Modal'
import EvolveDifference from 'components/EvolveDifference/EvolveDifference'
import Footer from 'components/Footer/Footer'
import FavoritesLoginModal from 'components/Favorites/FavoritesLogin/FavoritesLoginModal'
import { CancellationAlert } from 'components/CancellationAlert/CancellationAlert'
import { CancellationPolicy } from 'components/CancellationPolicy/CancellationPolicy'

import UrgencyBooking from './BookingUrgency'
import AskQuestion from './AskQuestion'
import RecommendedListings from './RecommendListings'
import Quote from './Quote'

import NavigationToCityLink from '../NavigationToCityLink'
import style from '../ResultDetails.module.scss'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

import { pushToDataLayer } from 'utils/Gtm'
import {
  buildDescription,
  buildMetaDescription,
  DateModifiedStructuredData,
  ListingStructuredData,
  WebpageStructuredData,
} from 'utils/StructuredData'
import { urlPrettySpaces } from 'utils/Strings'
import { searchStateToUrl } from 'utils/Search'
import getSelectedAvgPrice from 'utils/getSelectedAvgPrice'
import calculateServiceFeePercent from 'utils/calculateServiceFeePercent'
import type { CombinedListing } from 'utils/staticData'
import { metaTitleFormula } from 'utils/Listings'
import { scrollToId } from 'utils/scroll'
import capitalize from 'utils/strings/capitalize'
import getNestedValue from 'utils/getNestedValue'

import { publicOrigin } from 'constants/globalEnv'

import type { AutoLocationLandingData } from 'types/locationLanding'
import type {
  BreadcrumbListItem,
  QuotePayload,
  Review,
} from 'types/externalData'

type productViewedValues = {
  productId: string
  name: string
  price: string | number
  value: string | number
  currency: string
  travelStart: string
  travelEnd: string
  reviewScore: string | number
  reviewCount: string | number
  numberOfAdults: string | number
  numberOfChildren: string | number
  numberOfInfants: string | number
  numberOfPets: string | number
  city: string
  region: string
  country: string
  test_variant: string
  test_value: any
  guestServiceFeeAmount: string | number
  guestServiceFeePercent: string | number
}

// Functions
const buildCanonicalLink = (listing: CombinedListing) => {
  if (listing?.Country && listing.City && listing.State) {
    const country = urlPrettySpaces(listing.Country.toLowerCase())
    const state = urlPrettySpaces(listing.State.toLowerCase())
    const city = urlPrettySpaces(listing.City.toLowerCase())
    const id = listing.objectID
    return (
      `${publicOrigin}/vacation-rentals/${country}` +
      (state ? `/${state}/${city}/${id}` : `/${city}/${id}`)
    )
  }
}

export type ListingPageProps = {
  breadcrumbData: BreadcrumbListItem[]
  footerData: any
  evolveDifference: any
  listing: CombinedListing | null
  listingReviews: Review[] | null
  lastModifiedDate: string
}

const ListingPage: React.FC<ListingPageProps> = ({
  breadcrumbData,
  footerData,
  evolveDifference,
  listing,
  listingReviews,
  lastModifiedDate,
}) => {
  useBodyClass('Listing')
  const listingId = listing?.objectId ?? ''
  const router = useAugmentedRouter()
  const { inquiry_modal: inquiryModal, review_modal } = router.query
  const { availabilitySummary } = useListingAvailability({
    listingId,
  })
  const { guests, quote, dates, testData, bookingRef } =
    useContext(BookingContext)

  const postData: QuotePayload = useMemo(
    () => ({
      reservation: {
        numberOfAdults: guests && guests['adults'] ? +guests['adults'] : 0,
        numberOfChildren:
          guests && guests['children'] ? +guests['children'] : 0,
        numberOfPets: guests && guests['pets'] ? +guests['pets'] : 0,
        internalListingID: listing?.objectID,
        checkInDate:
          dates && dates['start']
            ? dayjs(dates['start']).format('YYYY-MM-DD')
            : '',
        checkOutDate:
          dates && dates['end'] ? dayjs(dates['end']).format('YYYY-MM-DD') : '',
      },
      optionalLineItems: [],
      distributor: {
        listingChannel: router.query.lc
          ? capitalize(getNestedValue(router.query.lc))
          : 'Website',
        clickId: router.query.clickId
          ? getNestedValue(router.query.clickId)
          : 'Default',
        deviceType: router.query.deviceType
          ? capitalize(getNestedValue(router.query.deviceType))
          : 'Desktop',
        market: router.query.market
          ? getNestedValue(router.query.market)
          : 'en_us',
        sessionid: router.query.sessionid
          ? getNestedValue(router.query.sessionid)
          : '',
        channel: 'Website',
      },
    }),
    [
      dates,
      guests,
      listing?.objectID,
      router.query.lc,
      router.query.clickId,
      router.query.deviceType,
      router.query.market,
      router.query.sessionid,
    ],
  )

  const {
    quote: quoteData,
    isLoading: isLoadingQuote,
    isValidating: isValidatingQuote,
    errorMessage: quoteErrorMessage,
    isError: quoteError,
  } = useQuote(postData)

  const cancellationRefunds = quoteData?.cancelPolicy
  const { avgPrice } = useAvgPrice({
    pricePerDay: availabilitySummary.pricePerDay,
    avgPricePerNight: +availabilitySummary.averagePrice,
    bookingDates: dates,
    fees: listing?.fees ?? {},
  })
  const averagePrice = parseInt(availabilitySummary?.averagePrice) || 0
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const { pageHistory } = useContext(PageHistoryContext)

  const detailMenuRefs = useRef<Record<string, HTMLElement | null>>({})

  const { data: session } = useSession()

  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)
  const isMobile = width <= LargeTabletBreakpoint
  const questionModalOpen = useSelect(
    (state) => state.resultDetail.questionModalOpen,
  )
  const reviewModalOpen = useSelect(
    (state) => state.resultDetail.reviewModalOpen,
  )
  const mobileSearchBoxFocused = useSelect(
    (state) => state.uiState.mobileSearchBoxFocused,
  )
  const loginPromptModalOpen = useSelect(
    (state) => state.uiState.loginPromptModalOpen,
  )
  const saveAsGuest = useSelect((state) => state.favorites.saveAsGuest)

  // Redux Actions
  const appDispatch = useDispatch()

  const { country: userCountry } = useGeolocation()

  const handleQuerySelect = useCallback(
    (query) => {
      const searchState = {
        query: query,
      }
      const searchRoute = searchStateToUrl(searchState)
      appDispatch(setQuery(query))
      appDispatch(setDetailPageUrl(router.pathname))
      router.push('/vacation-rentals/search' + searchRoute)
    },
    [appDispatch, router],
  )

  const handleCancellationAlertClick = () => {
    scrollToId('cancellationPolicy')
  }

  const gtmCallback = useCallback(() => {
    // push listingDetailView event once per page view
    if (listing?.units) {
      pushToDataLayer('view_item', {
        content_ids: [listing.objectID],
        city: listing.units?.[0].addresses[0].city,
        region: listing.units?.[0].addresses[0].state,
        country: listing.units?.[0].addresses[0].country,
        travel_start: dayjs(dates?.start).format('YYYY-MM-DD'),
        travel_end: dayjs(dates?.end).format('YYYY-MM-DD'),
        num_adults: guests?.adults,
        num_children: guests?.children,
        value: quote?.price?.total.toString(),
        currency: 'USD',
        ecommerce: {
          items: [
            {
              item_id: listing?.objectID,
              item_name: listing?.Headline,
              item_category: listing?.['Property Type'],
              price: listing?.['Average Per Night'],
            },
          ],
        },
      })
    }
  }, [
    guests?.adults,
    guests?.children,
    listing,
    quote?.price?.total,
    dates?.end,
    dates?.start,
  ])

  const hasDates = !!(dates?.start && dates?.end)
  const hasCancellationPolicyData =
    !isLoadingQuote && !quoteError && !!cancellationRefunds
  const shouldRenderMobileCancellationAlert =
    isMobile && (hasCancellationPolicyData || !hasDates)

  useEffect(() => {
    if (inquiryModal && !questionModalOpen) {
      document.body.classList.add('noScroll')
      appDispatch(openQuestionModal())
    }

    if (review_modal && !reviewModalOpen) {
      document.body.classList.add('noScroll')
      appDispatch(toggleReviewModal())
    }
  }, [
    appDispatch,
    inquiryModal,
    questionModalOpen,
    reviewModalOpen,
    review_modal,
  ])

  useEffect(() => {
    // first check if history has updated, then if on first page allow default referrer, otherwise pass previous page to referrer
    if (
      pageHistory[pageHistory.length - 1]?.split('?')[0] ===
      window.location.href.split('?')[0]
    ) {
      if (pageHistory.length === 1) {
        window.analytics.page('Listing Page')
      } else {
        window.analytics.page('Listing Page', {
          referrer: pageHistory[pageHistory.length - 2],
        })
      }
    }
  }, [pageHistory])

  //holds a check for wether the 'Product Viewed' Segment event should be fired
  const shouldSendProductViewedEvent = useRef<boolean>(false)
  //holds a check for wether the 'Product Viewed' Segment event has been fired
  const hasSentProductViewedEvent = useRef<boolean>(false)
  //holds a check for wether a quote has been generated (even if it has been lost)
  const hasGeneratedQuote = useRef<boolean>(false)
  //This is used to trigger the useeffect that sends the 'Product Viewed' event as well as to check against
  const [checkNewValues, setCheckNewValues] = useState(false)
  //Creates a simplified object to pass to the 'Product Viewed' event
  const productViewedValues = useRef<productViewedValues>({
    productId: '',
    name: '',
    price: 0,
    value: 0,
    currency: 'USD',
    travelStart: '',
    travelEnd: '',
    reviewScore: '',
    reviewCount: '',
    numberOfAdults: '',
    numberOfChildren: '',
    numberOfInfants: '',
    numberOfPets: '',
    city: '',
    region: '',
    country: '',
    test_variant: '',
    test_value: '',
    guestServiceFeeAmount: 0,
    guestServiceFeePercent: 0,
  })

  //this useEffect handles the refs needed for the 'Product Viewed' segment event
  useEffect(() => {
    const noGuests = !guests || guests?.adults === 0 ? true : false
    const noDates = !dates || !dates?.start ? true : false
    const oldValues = productViewedValues.current
    if (
      ((noGuests || noDates) &&
        availabilitySummary &&
        hasSentProductViewedEvent.current === false &&
        // REMOVE TEST DATA CONDITION AFTER CONCLUSION OF DD-624 and DD-625 A/B TESTS
        testData.variant !== '') ||
      (!noGuests && !noDates && quote) ||
      (noGuests && noDates && hasGeneratedQuote)
    ) {
      shouldSendProductViewedEvent.current = true
      productViewedValues.current = {
        productId: listing?.objectID || '',
        name: listing?.Headline || '',
        price:
          availabilitySummary?.availabilityDates &&
          quote?.checkInDate &&
          quote?.checkOutDate
            ? getSelectedAvgPrice(
                availabilitySummary?.availabilityDates,
                quote?.checkInDate,
                quote?.checkOutDate,
              )
            : averagePrice,
        value: quote?.price?.total || '',
        currency: 'USD',
        travelStart: dates?.start
          ? dayjs(dates.start).format('YYYY-MM-DD')
          : '',
        travelEnd: dates?.end ? dayjs(dates.end).format('YYYY-MM-DD') : '',
        reviewScore: listing?.['Average Rating'] || '',
        reviewCount: listing?.['Number of Reviews'] || '',
        numberOfAdults: guests?.adults || '',
        numberOfChildren: guests?.children || '',
        numberOfInfants: guests?.infants || '',
        numberOfPets:
          listing?.amenities?.Accessibility?.includes('Pets Allowed') &&
          guests?.pets
            ? guests.pets
            : '',
        city: listing?.City || '',
        region: listing?.State || '',
        country: listing?.Country || '',
        test_variant: testData.variant,
        test_value: testData.value,
        guestServiceFeeAmount:
          quote?.orderItems?.filter(
            (item) => item.name === 'Guest Service Fee',
          )[0]?.total || '',
        guestServiceFeePercent: quote?.orderItems?.filter(
          (item) => item.name === 'Guest Service Fee',
        )[0]?.total
          ? calculateServiceFeePercent(quote.orderItems)
          : '',
      }
      if (quote && hasGeneratedQuote.current === false) {
        hasGeneratedQuote.current = true
      }
    } else {
      shouldSendProductViewedEvent.current = false
    }
    setCheckNewValues(oldValues !== productViewedValues.current ? true : false)
  }, [
    availabilitySummary,
    dates,
    guests,
    listing,
    quote,
    averagePrice,
    testData.variant,
  ])

  useEffect(() => {
    if (
      shouldSendProductViewedEvent.current === true &&
      checkNewValues === true
    ) {
      window.analytics.track('Product Viewed', {
        session_id: localStorage.getItem('analytics_session_id'),
        product_id: listingId,
        name: productViewedValues.current.name,
        price: productViewedValues.current.price,
        value: productViewedValues.current.value,
        currency: productViewedValues.current.currency,
        travel_start: productViewedValues.current.travelStart,
        travel_end: productViewedValues.current.travelEnd,
        review_score: productViewedValues.current.reviewScore,
        review_count: productViewedValues.current.reviewCount,
        num_adults: productViewedValues.current.numberOfAdults,
        num_children: productViewedValues.current.numberOfChildren,
        num_infants: productViewedValues.current.numberOfInfants,
        num_pets: productViewedValues.current.numberOfPets,
        city: productViewedValues.current.city,
        region: productViewedValues.current.region,
        country: productViewedValues.current.country,
        test_variant: productViewedValues.current.test_variant,
        test_value: productViewedValues.current.test_value,
        guest_service_fee_amount:
          productViewedValues.current.guestServiceFeeAmount,
        guest_service_fee_percent:
          productViewedValues.current.guestServiceFeePercent,
        analytics_session_id: localStorage.getItem('analytics_session_id'),
      })
      if (hasSentProductViewedEvent.current === false) {
        hasSentProductViewedEvent.current = true
      }
    }
  }, [checkNewValues, quote, listingId])

  const hasIdentifiedUser = useRef<boolean>(false)
  useEffect(() => {
    if (session?.user && hasIdentifiedUser.current === false) {
      const splitName = session.user.name.split(' ')
      const firstName = splitName[0]
      const lastName = splitName[splitName.length - 1]
      window.analytics.identify(session.user.email?.toLowerCase(), {
        email: session.user.email?.toLowerCase(),
        firstName: firstName,
        lastName: lastName,
      })
      hasIdentifiedUser.current = true

      // identifyUser(session.user.email?.toLowerCase(), firstName, lastName)  // Removed temporarily due to not leveraging amplitude session replay
    }
  }, [session?.user])

  const metaTitle = listing?.meta?.title || metaTitleFormula(listing)
  const metaDescription = listing
    ? listing.meta.description || buildMetaDescription(listing)
    : ''

  const getHeadlineFromAdContent = () => {
    if (!listing) return null

    const headline = listing['adContent'].filter(
      ({ type }) => type === 'headline',
    )

    if (
      headline.length &&
      'text' in headline[0] &&
      headline[0]['text'] !== 'null'
    ) {
      return headline[0]['text']
    }

    return null
  }

  const amenities = useListingAmenities(listing)

  const onBreadcrumbItemClicked = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    bItem: BreadcrumbListItem,
    dataPath: string,
  ) => {
    e.preventDefault()

    const locationLandingClient = algoliasearch(
      `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
      `${process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX_API_KEY}`,
    )
    const locationLandingIndex = locationLandingClient.initIndex(
      process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX!,
    )

    const vacationRentalHome = `${publicOrigin}/vacation-rentals`
    const address = `${bItem.item}`.replace(vacationRentalHome, '').substring(1)

    const pageData =
      address.length > 0
        ? await locationLandingIndex
            .getObject<AutoLocationLandingData>(address)
            .catch((err) => console.error(err))
        : ({} as AutoLocationLandingData)

    if (!pageData) {
      router.push({
        pathname: '/vacation-rentals/search',
        query: {
          ...router.query,
          query: dataPath,
          prevListingPage: router.asPath.split('?')[0],
        },
      })
    } else {
      router.push(bItem.item)
    }
  }

  return (
    <div className={style.resultDetails}>
      {listing && (
        <>
          <Head>
            {listing.Headline && <title>{`${metaTitle} | Evolve`}</title>}
            <meta content={metaDescription} name="description" />
            {listing.Country && (
              <link href={buildCanonicalLink(listing)} rel="canonical" />
            )}
          </Head>
          <Gtm callback={gtmCallback} pageType="listing detail page" />

          <ListingOGData
            imageUrl={listing.ImageUrl}
            listing={listing}
            metaDescription={buildMetaDescription(listing)}
            title={`${listing.Headline} | Evolve`}
          />
          <Header page="listing">
            <SearchBox onQuerySelect={handleQuerySelect} />
          </Header>

          {/* <CancellationPolicyModal /> */}
          {listing && width <= 600 && (
            <FavoriteButton container="resultDetails" listing={listing} />
          )}
          {isMobile ? (
            <MobileImageGallery
              images={listing.images ?? listing?.units?.[0].images}
              location={
                listing._geoloc || listing.units?.[0].addresses[0].geoCode
              }
            />
          ) : (
            <DesktopImageGallery
              headline={
                'Headline' in listing
                  ? listing['Headline']
                  : getHeadlineFromAdContent()
              }
              images={listing.images ?? listing?.units?.[0].images}
              location={
                listing._geoloc || listing.units?.[0].addresses[0].geoCode
              }
            />
          )}
          <div
            className={`${style.resultDetailsContent} ${
              viewDetailsOpen ? style.viewDetailsOpen : ''
            }`}
          >
            <NavigationToCityLink
              listing={listing}
              viewDetailsOpen={viewDetailsOpen}
            />

            <UrgencyBooking
              availability={availabilitySummary}
              avgPrice={avgPrice}
              bookingDates={dates}
            />

            <div className={style.resultDetailsMain}>
              <DetailOverview
                avgPrice={avgPrice}
                detailMenuRefs={detailMenuRefs}
                listing={listing}
                quote={quote}
                setShareModalOpen={setShareModalOpen}
                shareModalOpen={shareModalOpen}
              />
              {shouldRenderMobileCancellationAlert ? (
                <CancellationAlert
                  dates={dates}
                  hasBorderBottom
                  onClick={handleCancellationAlertClick}
                  refunds={cancellationRefunds}
                />
              ) : null}
              {/* {isMobile && <CancellationPolicy loadingQuote={loadingQuote} />} */}
              <Description listing={listing} />
              <SleepingArrangements
                detailMenuRefs={detailMenuRefs}
                listing={listing}
              />
              <Amenities
                detailMenuRefs={detailMenuRefs}
                featured={amenities}
                listing={listing}
              />
              <NearbyActivities
                detailMenuRefs={detailMenuRefs}
                listing={listing}
              />
              <ResultMap detailMenuRefs={detailMenuRefs} listing={listing} />
              <LocationDetails
                detailMenuRefs={detailMenuRefs}
                listing={listing}
              />
              <CancellationPolicy dates={dates} refunds={cancellationRefunds} />
              <Reviews
                detailMenuRefs={detailMenuRefs}
                listing={listing}
                listingReviews={listingReviews}
              />

              <RecommendedListings
                avgPrice={avgPrice}
                bookingDates={dates}
                listing={listing}
              />
              <AskQuestion bookingRef={bookingRef} listingId={listingId} />
              <div className={style.difference}>
                <EvolveDifference
                  evolveDifference={evolveDifference}
                  small={true}
                  styleClass="responsiveLayout"
                />
              </div>
            </div>
            <div
              className={`${style.resultDetailsSidebar} ${
                mobileSearchBoxFocused ? style.hideSidebar : ''
              }`}
            >
              <div>
                {!questionModalOpen && !reviewModalOpen && !shareModalOpen && (
                  <Quote
                    amenities={amenities}
                    availability={availabilitySummary?.availabilityDates}
                    availabilityAvgPrice={avgPrice}
                    cancellationRefunds={cancellationRefunds}
                    isLoadingQuote={isLoadingQuote}
                    isValidatingQuote={isValidatingQuote}
                    listing={listing!}
                    minStay={availabilitySummary?.minStayDates}
                    quoteData={quoteData}
                    quoteError={quoteError}
                    quoteErrorMessage={quoteErrorMessage}
                    setViewDetailsOpen={setViewDetailsOpen}
                    viewDetailsOpen={viewDetailsOpen}
                  />
                )}
              </div>
            </div>
            <Breadcrumb
              items={breadcrumbData}
              onBreadcrumbItemClicked={onBreadcrumbItemClicked}
            />
          </div>
          <Footer footerData={footerData} />
          {questionModalOpen && (
            <QuestionModal
              amenities={amenities}
              availability={availabilitySummary?.availabilityDates}
              availabilityAvgPrice={avgPrice}
              cancellationRefunds={cancellationRefunds}
              country={userCountry ?? ''}
              isLoadingQuote={isLoadingQuote}
              isValidatingQuote={isValidatingQuote}
              listing={listing}
              minStay={availabilitySummary?.minStayDates}
              quoteData={quoteData}
              quoteError={quoteError}
              quoteErrorMessage={quoteErrorMessage}
              setViewDetailsOpen={setViewDetailsOpen}
              viewDetailsOpen={viewDetailsOpen}
            />
          )}
          {reviewModalOpen && <ReviewModal listing={listing} />}
          {loginPromptModalOpen && !saveAsGuest && !session && (
            <FavoritesLoginModal />
          )}
          {listingReviews && (
            <ListingStructuredData listing={listing} reviews={listingReviews} />
          )}
          {lastModifiedDate && (
            <DateModifiedStructuredData date={new Date(lastModifiedDate)} />
          )}
          {listing.Headline && (
            <WebpageStructuredData
              canonicalUrl={buildCanonicalLink(listing)}
              description={buildDescription(listing)}
              name={listing.Headline}
              yoastData={{}}
            />
          )}
        </>
      )}
    </div>
  )
}

export default withBookingErrorProvider(ListingPage)
